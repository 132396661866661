import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-embeddable',
  templateUrl: './embeddable.component.html',
  styleUrls: ['./embeddable.component.css']
})
export class EmbeddableComponent implements OnInit, OnDestroy {

  width = 400;
  height = 300;
  routeSubscription: Subscription;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.routeSubscription = this.route.queryParams.subscribe((params: Params) => {
      const width = params.width;
      const height = params.height;
      if (width) {
        this.width = width;
      }
      if (height) {
        this.height = height;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
